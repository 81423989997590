export const environment: any = {
  "production": true,
  "API": "https://api.expresspassport.com/api/",
  "client_url": "https://my.govworks.com",  
  "visas": false,
  "header": {
    "logo": "assets/png/expp_logo.png"
  },
  "support": {
    "phone": "8886129899"
  },
  "login": {
    "enabled": false,
    "logo": "assets/png/expp_logo.png"
  },
  "legal_links": {
    "terms": "https://www.expresspassport.com/terms-of-use/",
    "privacy": "https://www.expresspassport.com/privacy-policy/",
    "refund": "https://www.expresspassport.com/terms-of-use/#cancellations"
  },
  "source": {
    "domain": "expresspassport",
    "main_website": "https://www.expresspassport.com/",
    "promo_code": true,
    "name": "ExpressPassport"
  },
  "footer": {
    "logo": "assets/png/expp_logo.png",
    "logo_link": "https://www.expresspassport.com/",
    "copyright": "Express Passport"
  },
  "landing_pages": {
    "passport": {
      "enabled": false
    },
    "visa": {
      "enabled": false
    },
    "root_type": "passport"
  },
  "fedex_logo": true,
  "create_lead": true,
  "seals": {
    "trusted_site": true
  },
  "summary_cta_icon": true,
  "tags": {
    "analytics": "UA-110376739-1",
    "adwords": "AW-1030566672",
    "gtag_conv": "TC8NCMjs3oYBEJDmtOsD",
    "mouseflow": "50dc557c-f555-41a6-8778-f5d966560ca9"
  },
  "livechat": {
    "enabled": true,
    "license_id": "1073051"
  },
  "locations_page": {
    "enabled": false
  }
};